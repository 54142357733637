import React from "react";
import { selectedComp, selection_heading, sendAll } from "../utils/constants";

export default function Selection({ selection, setSelection }) {
  return (
    <div className="select">
      <div>{selection_heading}</div>
      <div>
        <input
          type="radio"
          name="sel"
          value="Send All Data"
          checked={selection}
          onChange={() => setSelection(true)}
        />
        <label onClick={() => setSelection(true)}>{sendAll}</label>
        <br />
        <input
          type="radio"
          name="sel"
          value="Send Selected Company Code Dat"
          checked={!selection}
          onChange={() => setSelection(false)}
        />
        <label onClick={() => setSelection(false)}>{selectedComp}</label>
        <br />
      </div>
    </div>
  );
}
