import React, { useState, useRef, useEffect } from "react";
import classes from "./MultiSelect.module.css";

export default function MultiSelect({ vals, setValues, fieldName }) {
  const [chips, addChips] = useState([]);
  const [showList, setShowList] = useState(false);
  const inputRef = useRef();
  const [filteredOptions, setOptions] = useState([...vals]);

  useEffect(() => {
    setValues(fieldName, chips);
  }, [chips]);

  const renderChips = () => {
    return (
      <div>
        {chips.map((ele) => {
          return (
            <span key={ele} className={classes.chip}>
              {ele}
              <span className={classes.chipx} onClick={() => removeChip(ele)}>
                x
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  const handleSearch = (e) => {
    let kwd = inputRef.current.value;
    if (kwd === "") {
      setShowList(false);
      setOptions([]);
      return;
    }
    setShowList(true);
    // console.log(kwd);
    let temp = vals.filter((x) => x.includes(kwd));
    // console.log(temp);
    setOptions(temp);
  };

  const renderOptions = () => {
    return (
      showList &&
      filteredOptions.length > 0 && (
        <div className={classes.options}>
          {filteredOptions.map((ele) => {
            return (
              <div
                key={ele}
                className={classes.option}
                onClick={() => {
                  if (!chips.includes(ele)) addChips([...chips, ele]);
                  setShowList(false);
                  inputRef.current.value = "";
                }}
              >
                {ele}
              </div>
            );
          })}
        </div>
      )
    );
  };
  const removeChip = (ele) => {
    let cur = chips.filter((x) => x !== ele);
    addChips([...cur]);
  };

  const inputkeyDown = (e) => {
    if (e.key === "Enter") {
      if (!chips.includes(e.target.value)) addChips([...chips, e.target.value]);
      inputRef.current.value = "";
    }
  };
  return (
    <div className={classes["chip-component"]}>
      {renderChips()}
      <input
        ref={inputRef}
        className={classes["chip-input"]}
        name={fieldName}
        // onClick={() => setShowList(true)}
        onChange={handleSearch}
        onKeyDown={inputkeyDown}
      />
      {renderOptions()}
    </div>
  );
}
