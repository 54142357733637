import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/constants";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    email: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          fetch(API_URL + "password_reset/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
              navigate("/product/view");
            })
            .catch(function (res) {
              console.log(res);
              setErr("Email not found");
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Email ID :</label>
            <Field name="email" />
            {errors.email && touched.email ? (
              <div className="error">{errors.email}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && <p style={{ color: "lightgreen" }}>Email Verified</p>}
            <button type="submit" style={{ fontWeight: "bolder" }}>
              Verify
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
