function isLowerCase(str) {
  return str === str.toLowerCase() && str !== str.toUpperCase();
}

export const Add_spaces = (item) => {
  let result = "";
  result = result + item[0];
  for (let i = 1; i < item.length; i++) {
    if (!isLowerCase(item[i]) && !(item[i - 1] === "I" && item[i] === "D"))
      result = result + " " + item[i];
    else result += item[i];
  }
  return result;
};

export const filterNavs = (navs, user) => {
  let filtered;
  if (!user.isAuthenticated) {
    filtered = navs.filter((item) => !item.isPrivate);
  } else if (user.role === "customer") {
    filtered = navs.filter((item) => !item.isAdmin);
  } else {
    filtered = navs;
  }
  return filtered;
};

export const removeSpecialCharacters = (str) => {
  let res = "",
    code;
  for (let i of str) {
    code = i.charCodeAt(0);
    if (
      !(code > 47 && code < 58) &&
      !(code > 64 && code < 91) &&
      !(code > 96 && code < 123)
    ) {
      res = res + " ";
    } else {
      res = res + i;
    }
  }
  console.log(res);
  return res;
};
