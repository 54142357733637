import React from "react";
import { useNavigate } from "react-router-dom";

export default function OptionsComponent({ item }) {
  const navigate = useNavigate();
  return (
    <div className="option">
      <p>{item.title}</p>
      <hr
        style={{
          height: "1px",
          borderWidth: "0px",
        }}
      ></hr>
      <div className="finance">
        {item.types.map((type) => {
          return (
            <div key={type}>
              <input type="radio" name="all" value={type} />
              <label
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    "/" + type.split(" ").join("").toLowerCase() + "/selection"
                  )
                }
              >
                {type}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
