import React, { useEffect, useState } from "react";
import classes from "./userdetaile.module.css";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

export default function UserDetails() {
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    fetch(API_URL + "get-users/")
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        setData(post);
      })
      .catch((err) => {});
  }, []);

  const removeUser = (email) => {
    setMsg("Deleting....");
    fetch(API_URL + "delete-users/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((dat) => {
        if (dat.status === 404) throw new Error("not found");
        let temp = data.filter((d) => d.email !== email);
        setData(temp);
        setMsg("Deleted");
      })
      .catch((err) => {
        console.log(err);
        setMsg("Unable to delete try again!!");
      });
  };
  return (
    <div className="log-con">
      {msg && (
        <div className={classes.status}>
          <div className={classes.fail}>{msg}</div>
          <div>
            <button onClick={() => setMsg("")}>X</button>
          </div>
        </div>
      )}
      {data.length > 0 ? (
        <table className="container">
          <thead>
            <tr>
              {Object.keys(data[0]).map((x) => {
                return (
                  <th key={x}>
                    <h1>{x}</h1>
                  </th>
                );
              })}
              <th>
                <h1>Actions</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr key={d.username}>
                  {Object.values(d).map((x) => {
                    return <td key={x}>{x}</td>;
                  })}
                  <td
                    style={{
                      fontSize: "large",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ color: "#d62931", cursor: "pointer" }}
                      onClick={() => removeUser(d.email)}
                    >
                      <AiOutlineDelete />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => {}}>
                      <FaEdit />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Overlay closeMethod={() => {}} isLoader={true}></Overlay>
      )}
    </div>
  );
}
