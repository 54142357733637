import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Product.css";
import { API_URL, postKey, pushUrls } from "../../utils/constants";
import {
  Add_spaces,
  removeSpecialCharacters,
} from "../../utils/helperFunctions";
import Overlay from "../overlay/Overlay";

export default function Product() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [showPop, setShowPop] = useState(false);
  const [msg, setMsg] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // console.log(state);
  const handleSelection = (item) => {
    item = JSON.stringify(item);
    if (selectedProducts.includes(item)) {
      setSelectedProducts(selectedProducts.filter((x) => x !== item));
    } else {
      setSelectedProducts([...selectedProducts, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedProducts([]);
    } else {
      for (let item of state[1]) {
        if (
          !selectedProducts.includes(
            JSON.stringify({
              [postKey[state[0]][0]]: item[postKey[state[0]][0]],
            })
          )
        )
          selectedProducts.push(
            JSON.stringify({
              [postKey[state[0]][0]]: item[postKey[state[0]][0]],
            })
          );
      }
      setSelectedProducts([...selectedProducts]);
    }
  };

  const createPayload = () => {
    let values = [];
    for (let item of state[1]) {
      if (
        selectedProducts.includes(
          JSON.stringify({ [postKey[state[0]][0]]: item[postKey[state[0]][0]] })
        )
      ) {
        values.push({
          [postKey[state[0]][0]]: item[postKey[state[0]][0]],
          [postKey[state[0]][1]]: item[postKey[state[0]][1]],
        });
      }
    }
    // values = values.map((x) => JSON.parse(x));
    values = [...values, { object_name: state[0] }];
    return values;
  };
  const handlePush = (e) => {
    e.stopPropagation();
    // let values = [...selectedProducts];
    let values = createPayload();
    setLoading(true);
    fetch(API_URL + pushUrls[state[0]], {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(async (res) => {
        let data = await res.json();
        // console.log(data);
        setLoading(false);
        let stats = data.filter((x) => x.status !== 200).map((x) => x.message);
        if (stats.length === 0)
          setMsg(["Successfully pushed the data to S4 cloud!!"]);
        else setMsg(stats);
        setShowPop(false);
      })
      .catch(function (res) {
        // console.log(res);
        // setMsg(res);
        setLoading(false);
        setShowPop(false);
      });
  };

  const getTransactions = () => {
    let values = createPayload();
    // console.log(values[0]);
    fetch(API_URL + "transactions/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(async (res) => {
        let data = await res.json();
        // console.log(data);
        navigate("/product/transactions", {
          state: data,
        });
      })
      .catch(function (res) {
        // console.log(res);
      });
  };
  if (state[1].length === 0) {
    return (
      <>
        <h2 style={{ textAlign: "center" }}>No Records Available!!</h2>
      </>
    );
  }
  return (
    <div className="product-con">
      <div className="title">
        {removeSpecialCharacters(state[0]).toUpperCase()}
      </div>
      <div className="prod-actions">
        <button onClick={() => setShowPop(true)}>Push to S4 Cloud</button>
        <button onClick={getTransactions}>Transaction logs</button>
      </div>
      {/* {msg.length > 0 && (
        <div className="msg">
          <div>
            {msg.map((m) => {
              return (
                <div key={m} className={m.status === 200 ? "success" : "fail"}>
                  {m.message}
                </div>
              );
            })}
          </div>
          <div>
            <button onClick={() => setMsg([])}>X</button>
          </div>
        </div>
      )} */}
      <table className="container">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={selectAll}
                checked={selectedProducts.length === state[1].length}
              ></input>
            </th>
            {Object.keys(state[1][0]).map((x) => {
              return (
                <th key={x}>
                  <h1>{Add_spaces(x)}</h1>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {state[1].map((d) => {
            return (
              <tr>
                <td key={d[state[0]]}>
                  <input
                    type="checkbox"
                    checked={
                      selectedProducts.filter(
                        (x) =>
                          x ===
                          JSON.stringify({
                            [postKey[state[0]][0]]: d[postKey[state[0]][0]],
                          })
                      ).length > 0
                    }
                    onChange={() => {
                      handleSelection({
                        [postKey[state[0]][0]]: d[postKey[state[0]][0]],
                      });
                    }}
                  ></input>
                </td>
                {Object.values(d).map((x) => {
                  return <td>{x}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showPop && (
        <Overlay closeMethod={() => setShowPop(false)} isLoader={false}>
          <p>Want to Continue ?</p>
          <div className="pop-btn">
            <button onClick={(e) => handlePush(e)}>YES</button>
            <button onClick={() => setShowPop(false)}>NO</button>
          </div>
        </Overlay>
      )}
      {msg.length > 0 && (
        <Overlay closeMethod={() => setMsg([])} isLoader={false}>
          <div style={{ textAlign: "left" }}>
            <ul>
              {msg.map((message) => {
                return <li key={message}>{message}</li>;
              })}
            </ul>
          </div>
          <div className="pop-btn">
            <button onClick={() => setMsg([])}>Close</button>
          </div>
        </Overlay>
      )}
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
