import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "./overlay/Overlay";
import { API_URL } from "../utils/constants";
import SelectPlant from "./select-plant/SelectPlant";
import MultiSelect from "./multi-select/MultiSelect";

export default function ProductSelection() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  // const [selectPlant, setPlant] = useState(false);
  const options = ["1710", "201", "202", "3910", "1110"];
  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Product: Yup.string().required("Required"),
    ProductType: Yup.string().required("Required"),
    // materialType: Yup.string().required("Required"),
    Plant: Yup.array()
      .required("Required")
      .min(1, "Atleast one value should be selected"),
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{
          Product: "",
          ProductType: "",
          Plant: [],
          // materialType: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          // console.log(values);
          setLoading(true);
          fetch(API_URL + "product_view/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setLoading(false);
              navigate("/product/view", {
                state: ["product", data],
              });
            })
            .catch(function (res) {
              console.log(res);
              setErr(res);
              setLoading(false);
            });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="main">
            <label>Product :</label>
            <Field name="Product" />
            {errors.Product && touched.Product ? (
              <div className="error">{errors.Product}</div>
            ) : null}
            <label>ProductType :</label>
            <Field name="ProductType" />
            {errors.ProductType && touched.ProductType ? (
              <div className="error">{errors.ProductType}</div>
            ) : null}
            <label>Plant</label>
            <MultiSelect
              vals={options}
              fieldName="Plant"
              setValues={setFieldValue}
            />
            {errors.Plant && touched.Plant ? (
              <div className="error">{errors.Plant}</div>
            ) : null}
            {/* <label>MaterialType :</label>
            <Field name="materialType" />
            {errors.materialType && touched.materialType ? (
              <div className="error">{errors.materialType}</div>
            ) : null}  */}
            {err && <p className="error">{err}</p>}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {/* <button style={{ width: "20%" }} onClick={() => setPlant(true)}>
        Select Plant
      </button>
      {selectPlant && (
        <Overlay closeMethod={() => setPlant(false)} isLoader={false}>
          <SelectPlant closeMethod={() => setPlant(false)} />
        </Overlay>
      )} */}
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
