import React from "react";
import "./Overlay.css";

export default function Overlay({ children, closeMethod, isLoader }) {
  return (
    <div className="overlay" onClick={() => closeMethod(false)}>
      <div className="con-pop" onClick={(e) => e.stopPropagation()}>
        {isLoader ? (
          <div className="center">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        ) : (
          <div className="dynamic">{children}</div>
        )}
      </div>
    </div>
  );
}
