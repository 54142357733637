import React from "react";
import { useState } from "react";
import Selection from "./components/Selection";
import Alldata from "./components/Alldata";
import Companydata from "./components/Companydata";

export default function Home() {
  const [selection, setSelection] = useState(true);
  return (
    <>
      <Selection {...{ selection, setSelection }} />
      {selection ? <Alldata /> : <Companydata />}
    </>
  );
}
