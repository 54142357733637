import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { API_URL } from "../utils/constants";

export default function ChangePwd() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    old: Yup.string().required("Required"),
    new: Yup.string().required("Required"),
    new_confirm: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new"), null], "Passwords must match"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          old: "",
          new: "",
          new_confirm: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          console.log(values);
          fetch(API_URL + "accounts/password_change/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
            })
            .catch(function (res) {
              console.log(res);
              setErr("Error Occurred");
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Old Password :</label>
            <Field name="old" type={"password"} />
            {errors.old && touched.old ? (
              <div className="error">{errors.old}</div>
            ) : null}
            <label>New Password :</label>
            <Field name="new" type={"password"} />
            {errors.new && touched.new ? (
              <div className="error">{errors.new}</div>
            ) : null}
            <label>Confirm New Password :</label>
            <Field name="new_confirm" type={"password"} />
            {errors.new_confirm && touched.new_confirm ? (
              <div className="error">{errors.new_confirm}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Changed successfully!!</p>
            )}
            <div>
              <button type="submit">Submit</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
