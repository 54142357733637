import React, { useState } from "react";
import "./index.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";

export default function Characteristic() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    Characteristic: Yup.string().required("Required"),
    // CharacteristicType: Yup.string().required("Required"),
    // Account_Group: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          Characteristic: "",
          // CharacteristicType: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          console.log(values);
          setLoading(true);
          fetch(API_URL + "char_view/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              navigate("/product/view", {
                state: ["CharcInternalID", data],
              });
            })
            .catch(function (res) {
              console.log(res);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Characteristic ID :</label>
            <Field name="Characteristic" />
            {errors.Characteristic && touched.Characteristic ? (
              <div className="error">{errors.Characteristic}</div>
            ) : null}
            {/* <label>Characteristic Type :</label>
            <Field name="CharacteristicType" />
            {errors.CharacteristicType && touched.CharacteristicType ? (
              <div className="error">{errors.CharacteristicType}</div>
            ) : null} */}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
