import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { certified, header } from "../utils/constants";
import { AuthData } from "../App";
import Profile from "./profile/Profile";

export default function Header() {
  const navigate = useNavigate();
  const logo = require("./../assests/logo.png");
  const { user } = AuthData();
  return (
    <div className="page-container">
      <div className="content-wrap">
        <div className="header">
          <img
            alt="VEROSAI-icon"
            src={logo}
            onClick={() => navigate("/pre-load")}
          />
          <div>{header}</div>
          <div className="header-right">
            <div style={{ marginRight: "10px" }}>{certified}</div>
            <img
              alt="SAP-Icon"
              src="https://sainav.com/wp-content/uploads/2024/01/csm_sap-partner-logo_4e3c3d048f.png"
            />
          </div>
          {user.isAuthenticated && <Profile />}
        </div>
        <Outlet />
      </div>
      <div className="footer">
        <p>Copyright © 2024 verosai. All Rights Reserved.</p>
      </div>
    </div>
  );
}
