import React, { useState } from "react";
import { API_URL } from "../utils/constants";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function SpecConfig() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    objecttype: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    post: Yup.string().required("Required"),
    put: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          objecttype: "",
          name: "",
          post: "",
          put: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          // console.log(values);
          fetch(API_URL + "specificonfig/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              console.log(data);
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              // navigate("/product/view", {
              //   state: ["Product", data],
              // });
            })
            .catch(function (res) {
              console.log(res);
              setErr("Error occurred");
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>ObjectType :</label>
            <Field name="objecttype" />
            {errors.objecttype && touched.objecttype ? (
              <div className="error">{errors.objecttype}</div>
            ) : null}
            <label>NameOfAPI :</label>
            <Field name="name" />
            {errors.name && touched.name ? (
              <div className="error">{errors.name}</div>
            ) : null}
            <label>Post URL :</label>
            <Field name="post" />
            {errors.post && touched.post ? (
              <div className="error">{errors.post}</div>
            ) : null}
            <label>Put URL :</label>
            <Field name="put" />
            {errors.put && touched.put ? (
              <div className="error">{errors.put}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
