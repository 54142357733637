import React from "react";
import { useNavigate } from "react-router-dom";

export default function SystemTiles() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="card" onClick={() => navigate("/tenant-view")}>
        <p>Tenant</p>
      </div>
      <div className="card" onClick={() => navigate("/tenant-user-view")}>
        <p>Tenant User</p>
      </div>
      <div className="card" onClick={() => navigate("/tenant-op-view")}>
        <p>Tenant Operation</p>
      </div>
    </div>
  );
}
