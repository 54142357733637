import React from "react";
import OptionsComponent from "./OptionsComponent";
import { options } from "../utils/constants";

export default function Alldata() {
  return (
    <div className="alldata">
      {options.map((item) => {
        return <OptionsComponent item={item} key={item.title} />;
      })}
    </div>
  );
}
