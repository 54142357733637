import Characteristic from "./components/characteristic/Characteristic";
import Odata from "./components/Odata";
import OdataView from "./components/odataView/OdataView";
import Tenant from "./components/Tenant";
import LoadData from "./components/LoadProcess/LoadData";
import TenantView from "./components/TenantView";
import TenantEdit from "./components/TenantEdit";
import TenantForm from "./components/tenant_user/TenantForm";
import TenantUserView from "./components/tenant_user/TenantUserView";
import TenantUserEdit from "./components/tenant_user/TenantUserEdit";
import TenantOpForm from "./components/tenant_op/TenantOpForm";
import TenantOpView from "./components/tenant_op/TenantOpView";
import TenantOpEdit from "./components/tenant_op/TenantOpEdit";
import BPcustomer from "./components/BPcustomer";
import BPsupplier from "./components/BPsupplier";
import Config from "./components/Config";
import Costcenter from "./components/Costcenter";
import Login from "./components/Login/Login";
import Verify from "./components/Login/Verify";
import Logs from "./components/Logs/Logs";
import Product from "./components/Product/Product";
import WorkCenter from "./components/WorkCenter";
import ProductSelection from "./components/ProductSelection";
import SpecConfig from "./components/SpecConfig";
import Tile from "./components/Tile";
import Transactions from "./components/Transactions";
import Home from "./Home";
import Routing from "./components/Routing";
import SystemTiles from "./components/SystemTiles";
import Classification from "./components/Classification";
import ChangePwd from "./components/ChangePwd";
import VerifyEmail from "./components/forgot-password/VerifyEmail";
import PasswordReset from "./components/forgot-password/PasswordReset";
import CustomerProduction from "./components/CustomerProduction";
import VendorProduction from "./components/VendorProduction";
import Bank from "./components/Bank";
import UserDetails from "./components/user-details/UserDetails";
import DataTiles from "./components/DataTiles";
import SelectPlant from "./components/select-plant/SelectPlant";
export const navs = [
  {
    path: "/",
    element: <Login />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/accounts/reset/:uidb/:token",
    element: <PasswordReset />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/signup",
    element: <Verify />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/user-details",
    element: <UserDetails />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/change-pwd",
    element: <ChangePwd />,
    isPrivate: true,
    isAdmin: false,
  },
  // {
  //   path: "/verify",
  //   element: <Verify />,
  //   isPrivate: false,
  //   isAdmin: true,
  // },
  {
    path: "/pre-load",
    element: <Tile />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/home",
    element: <Home />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/config",
    element: <Config />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/sconfig",
    element: <SpecConfig />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/system-tiles",
    element: <SystemTiles />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/data-tiles",
    element: <DataTiles />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/product-configuration",
    element: <SelectPlant />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant",
    element: <Tenant />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-view",
    element: <TenantView />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-edit",
    element: <TenantEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-user",
    element: <TenantForm />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-user-view",
    element: <TenantUserView />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-user-edit",
    element: <TenantUserEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-op",
    element: <TenantOpForm />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-op-view",
    element: <TenantOpView />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-op-edit",
    element: <TenantOpEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/odata",
    element: <Odata />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/odataView",
    element: <OdataView />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/product/selection",
    element: <ProductSelection />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/:object/view",
    element: <Product />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/logs/selection",
    element: <Logs />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/costcenter/selection",
    element: <Costcenter />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/customerproduction/selection",
    element: <CustomerProduction />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/vendorproduction/selection",
    element: <VendorProduction />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/workcenter/selection",
    element: <WorkCenter />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/routing/selection",
    element: <Routing />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/product/transactions",
    element: <Transactions />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/bp-supplier/selection",
    element: <BPsupplier />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/bp-customer/selection",
    element: <BPcustomer />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/bank/selection",
    element: <Bank />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/characteristic/selection",
    element: <Characteristic />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/classification/selection",
    element: <Classification />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/load-data",
    element: <LoadData />,
    isPrivate: true,
    isAdmin: false,
  },
];
