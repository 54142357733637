import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthData } from "../App";

export default function Tile() {
  const navigate = useNavigate();
  const { user } = AuthData();
  return (
    <div className="tile-container">
      <div className="card" onClick={() => navigate("/home")}>
        <p>Migration of Product Data</p>
      </div>
      <div className="card" onClick={() => navigate("/odataView")}>
        <p>Data Lake</p>
      </div>
      {user.role === "admin" && (
        <>
          <div className="card" onClick={() => navigate("/system-tiles")}>
            <p>System Configurations</p>
          </div>
          <div className="card" onClick={() => navigate("/signup")}>
            <p>Add User</p>
          </div>
          <div className="card" onClick={() => navigate("/user-details")}>
            <p>All User's Details</p>
          </div>
          <div className="card" onClick={() => navigate("/data-tiles")}>
            <p>Data Configurations</p>
          </div>
        </>
      )}
      <div className="card" onClick={() => navigate("/load-data")}>
        <p>Load Data</p>
      </div>
    </div>
  );
}
