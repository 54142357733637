import React from "react";
import { useNavigate } from "react-router-dom";

export default function DataTiles() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="card" onClick={() => navigate("/product-configuration")}>
        <p>Configurations for Product</p>
      </div>
      <div className="card" onClick={() => {}}>
        <p>Configurations for Customer</p>
      </div>
      <div className="card" onClick={() => {}}>
        <p>Configurations for Vendor</p>
      </div>
    </div>
  );
}
