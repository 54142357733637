import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "./overlay/Overlay";
import { API_URL } from "../utils/constants";

export default function BPsupplier() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Supplier: Yup.string().required("Required"),
    // Business_Partner: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          Supplier: "",
          // Business_Partner: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          console.log(values);
          setLoading(true);
          fetch(API_URL + "bpsupplier_view/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setLoading(false);
              navigate("/bp_supplier/view", {
                state: ["bp_supplier", data],
              });
            })
            .catch(function (res) {
              console.log(res);
              setErr(res);
              setLoading(false);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Supplier :</label>
            <Field name="Supplier" />
            {errors.Supplier && touched.Supplier ? (
              <div className="error">{errors.Supplier}</div>
            ) : null}
            {/*<label>Business Partner :</label>*/}
            {/*<Field name="Business_Partner" />*/}
            {/*{errors.Business_Partner && touched.Business_Partner ? (*/}
            {/*  <div className="error">{errors.Business_Partner}</div>*/}
            {/*) : null}*/}
            {err && <p className="error">{err}</p>}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
