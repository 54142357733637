import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi2";
import { AuthData } from "./../../App";
import "./profile.css";

export default function Profile() {
  const navigate = useNavigate();
  const { logout } = AuthData();
  return (
    <div className="profile">
      <HiUserCircle />
      <div className="profile-options">
        <div
          onClick={() => {
            navigate("/change-pwd");
          }}
        >
          Change Password
        </div>
        <div
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          Log Out
        </div>
      </div>
    </div>
  );
}
