export const header = "Migrate Master Data to S4 Public Cloud";
export const selection_heading = "Selection Screen";
export const sendAll = "Send All Data";
export const selectedComp = "Send Selected Company Code Data";
export const certified = "We Are Certified";
export const API_URL = "https://verosdata.com/api/";
// export const API_URL = "http://localhost:8000/";
export const pushUrls = {
  product: "product_push/",
  WorkCenter: "workcenter/",
  costcenter: "costcenter_push/",
  CharcInternalID: "char_push/",
  ProductionRoutingGroup: "productionrouting/",
  classification: "classification_push/",
  bp_supplier: "bpsupplier_push/",
  bp_customer: "bpcustomer_push/",
  bank: "bank_push/",
};
export const postKey = {
  product: ["Product", "ProductType"],
  WorkCenter: ["WorkCenter", "WorkCenterTypeCode"],
  costcenter: ["CostCenter", "CostCenter"],
  CharcInternalID: "Characteristic",
  ProductionRoutingGroup: ["ProductionRoutingGroup", "ProductionRouting"],
  classification: ["ClassInternalID", "ClassType"],
  bp_supplier: ["Supplier", "BusinessPartner"],
  bp_customer: ["Customer", "BusinessPartner"],
  bank: ["BankInternalID", "BankInternalID"],
};
export const OData_keys = [
  "Object Type",
  "Operation",
  "Service URL (CDS View)",
  "Object",
  "Tenant ID",
  "Filters",
];
export const options = [
  {
    title: "Finance",
    types: [
      "Bank",
      "Profit Center",
      "Cost Center",
      "G/L Account",
      "Asset",
      "Cost Element",
      "Activity Type",
    ],
  },
  {
    title: "General Master Data",
    types: [
      "BP-Supplier",
      "BP-Customer",
      "BP-Employee",
      "Product",
      "BOM",
      "Change Number",
      "logs",
    ],
  },
  {
    title: "Purchasing",
    types: ["Purchasing Info Record", "Source List"],
  },
  {
    title: "Manufacturing",
    types: ["Routing", "Work center"],
  },
  {
    title: "Quality Management Master",
    types: [
      "Inspection PI",
      "MIC",
      "QA Insp. Pro",
      "Characteristic",
      "Classification",
    ],
  },
];

export const object_names = [
  "product",
  "bp_customer",
  "bp_supplier",
  "workcenter",
  "classification",
  "costcenter",
  "characteristic",
  "routing",
];

export const tenant_fields = ["Tenant ID", "Status", "Tenant Name"];

export const tenant_user_fields = ["Tenant ID", "User Name", "Password"];
export const tenant_op_fields = [
  "OData Type",
  "Operation",
  "URL",
  "Object Name",
  "Tenant",
];
